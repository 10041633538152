
import { Component, Prop, Vue } from 'vue-property-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import {
  TASQ_NO_COMMS_TYPE,
  TASQ_PREDICTION_TYPE,
  TASQ_FAILURE_TYPE,
  TASQ_DOWN_TYPE,
  TASQ_OFF_TARGET_TYPE,
  TASQ_LABEL_TYPE,
  TASQ_PRODUCING_TYPE,
  TASQ_PM_TYPE,
  TASQ_GAUGE_TYPE,
  TASQ_REGULATORY_TYPE,
  TASQ_SITE_CHECK_TYPE,
  TASQ_SETPOINT_TYPE,
  TASQ_WELL_TEST_TYPE,
  TASQ_SCHEDULED_JOB,
  TASQ_FAILURE_PREDICTION_TYPE,
  TASQ_STATE_CHANGE_TYPE,
  TASQ_OFF_TARGET_RT_TYPE,
  TASQ_WORKFLOW_TYPE,
  TASQ_PLUNGER_CHANGE,
  TASQ_STANDING_VALVE,
} from '@/lib/constants';

@Component({
  components: {},
})
export default class TasqItem extends Vue {

  @Prop({ type: Object, required: true }) tasq?: any;


  @Prop({ type: Boolean, required: false }) isTasqActive?: boolean;

  @Prop({ type: String, required: false, default: '' }) bgClasses?: string;


  get bgClass(){
    if(this.bgClasses){
      return this.bgClasses
    }
    return this.getColorClass(this.tasq.predictionType)
  }

  getColorClass = (predictionType) => {
    switch (predictionType) {
      case TASQ_PLUNGER_CHANGE:
      case TASQ_STANDING_VALVE:
        return 'bg-blue005';
      case TASQ_PREDICTION_TYPE:
      case TASQ_FAILURE_PREDICTION_TYPE:
        return 'bg-green015';
      case TASQ_FAILURE_TYPE:
      case TASQ_OFF_TARGET_RT_TYPE:
        return 'bg-red001';
      case TASQ_DOWN_TYPE:
      case TASQ_OFF_TARGET_TYPE:
        return 'bg-orange002';
      case TASQ_LABEL_TYPE:
        return 'bg-blue002';
      case TASQ_PRODUCING_TYPE:
      case TASQ_PM_TYPE:
      case TASQ_GAUGE_TYPE:
      case TASQ_REGULATORY_TYPE:
      case TASQ_SITE_CHECK_TYPE:
      case TASQ_NO_COMMS_TYPE:
      case TASQ_WELL_TEST_TYPE:
      case TASQ_SCHEDULED_JOB:
        return 'bg-gray008';
      case TASQ_WORKFLOW_TYPE:
      return 'bg-violet001';
      case TASQ_SETPOINT_TYPE:
        return 'bg-blue005';
      default:
        return 'bg-gray008';
    }
  };


}
